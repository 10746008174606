import React  from "react";
import ProductDetails from "../../components/shopping/ProductDetails";
import { useTranslation } from "react-i18next";
const ProductDetailsLayout = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="page_head_top">{t("product_details")}</div>
            <ProductDetails />
        </>
    )
}

export default ProductDetailsLayout