import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import SponserTreeContent from "../../components/Tree/sponser-tree/SponserTreeContent";
import { useState } from "react";
import { ApiHook } from "../../hooks/apiHook";

const SponserTree = () => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [doubleClickedUser, setDoubleClickedUser] = useState("");
  // api
  const sponserTree = ApiHook.CallSponsorTreeList("", "", searchUsername);
  return (
    <>
      <TreeViewNavbar
        menu={"sponsorTree"}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
        setSelectedUserId={setSelectedUserId}
      />
      <SponserTreeContent
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        doubleClickedUser={doubleClickedUser}
        setDoubleClickedUser={setDoubleClickedUser}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
        isLoading={sponserTree.status === "loading" || sponserTree.fetchStatus === "fetching"}
      />
    </>
  );
};

export default SponserTree;