import axios from 'axios';
import React, { useState } from 'react';

const AuthorizeNetForm = ({ handleSubmit, paymentId }) => {
    const [formData, setFormData] = useState({
        cardNumber: '',
        expirationDate: '',
        cvv: '',
        firstName: '',
        lastName: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;


        if (name === 'expirationDate') {
            // Remove any non-digit characters
            const digitsOnly = value.replace(/\D/g, '');

            // Format the expiration date
            let formattedValue = digitsOnly;
            if (digitsOnly.length > 2) {
                formattedValue = digitsOnly.slice(0, 2) + '/' + digitsOnly.slice(2, 4);
            }

            // Update the state
            setFormData(prevState => ({
                ...prevState,
                [name]: formattedValue
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmitPay = (e) => {
        e.preventDefault();
        handleSubmit(paymentId, formData);
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        boxSizing: 'border-box',
    };

    const cardStyle = {
        width: '100%',
        maxWidth: '400px',
        height: '250px',
        background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
        borderRadius: '20px',
        padding: '25px',
        boxSizing: 'border-box',
        color: 'white',
        position: 'relative',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
        marginBottom: '30px',
        overflow: 'hidden',
    };

    const patternStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(255,255,255,0.1) 1px, transparent 0)',
        backgroundSize: '20px 20px',
        opacity: 0.5,
    };

    const chipStyle = {
        width: '40px',
        height: '30px',
        background: '#fcc932',
        borderRadius: '5px',
        marginBottom: '40px',
    };

    const logoStyle = {
        position: 'absolute',
        top: '20px',
        right: '25px',
        fontSize: '24px',
        fontWeight: 'bold',
    };

    const cardNumberStyle = {
        fontSize: '26px',
        letterSpacing: '2px',
        marginTop: '60px',
        marginBottom: '40px',
    };

    const infoStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
    };

    const labelStyle = {
        fontSize: '10px',
        marginBottom: '5px',
        textTransform: 'uppercase',
        opacity: 0.8,
    };

    const formStyle = {
        width: '100%',
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    };

    const inputStyle = {
        padding: '12px',
        fontSize: '16px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        width: '100%',
        boxSizing: 'border-box',
    };

    const buttonStyle = {
        padding: '12px',
        fontSize: '16px',
        background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
        color: 'white',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        width: '100%',
        transition: 'background-color 0.3s',
    };

    return (
        <div style={containerStyle}>
            <div style={cardStyle}>
                <div style={patternStyle}></div>
                <div style={chipStyle}></div>
                <div style={logoStyle}>CARD</div>
                <div style={cardNumberStyle}>
                    {formData.cardNumber
                        ? formData.cardNumber.replace(/(\d{4})(?=\d)/g, '$1 ')
                        : '•••• •••• •••• ••••'}
                </div>
                <div style={infoStyle}>
                    <div>
                        <div style={labelStyle}>Holder</div>
                        <div>{formData.firstName || formData.lastName ? formData.firstName + ' ' + formData.lastName ?? '' : 'FULL NAME'}</div>
                    </div>
                    <div>
                        <div style={labelStyle}>Expires</div>
                        <div>{formData.expirationDate || 'MM/YY'}</div>
                    </div>
                    <div>
                        <div style={labelStyle}>CVV</div>
                        <div>{formData.cvv || '***'}</div>
                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmitPay} style={formStyle}>
                <input
                    type="text"
                    name="cardNumber"
                    placeholder="Card Number"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    required
                    style={inputStyle}
                />
                <div style={{ display: 'flex', gap: '15px' }}>
                    <input
                        type="text"
                        name="expirationDate"
                        placeholder="MM/YY"
                        value={formData.expirationDate}
                        onChange={handleChange}
                        required
                        style={{ ...inputStyle, width: '50%' }}
                    />
                    <input
                        type="text"
                        name="cvv"
                        placeholder="CVV"
                        value={formData.cvv}
                        onChange={handleChange}
                        required
                        style={{ ...inputStyle, width: '50%' }}
                    />
                </div>
                <input
                    type="text"
                    name="firstName"
                    placeholder="Cardholder First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    style={inputStyle}
                />
                <input
                    type="text"
                    name="lastName"
                    placeholder="Cardholder Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    style={inputStyle}
                />
                <button type="submit" style={buttonStyle}>SUBMIT</button>
            </form>
        </div>
    );
};

export default AuthorizeNetForm;