import React, { useRef, useState } from "react";
import LabelledButton from "../buttons/LabelledButton";
import { useReactToPrint } from "react-to-print";
import { exportToCSV, exportToExcel } from "../../../utils/tableExports";
import { PrintReport } from "../PrintReport";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { ApiHook } from "../../../hooks/apiHook";
// import { toast } from "react-toastify";

const RepurchaseTableFilter = ({ headers, data, type, reportHeaders }) => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });
  const currencySymbol = useSelector((state) => state.user.selectedCurrency.symbolLeft);

  const handleBack = () => {
    navigate('/shopping')
  }

   // Translation dictionary for status
   const statusTranslations = {
    "1": t("approved"),
    "0": t("pending"),
  };

  // Function to translate specific fields
  const addLangToValues = (obj) => {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key === "paymentMethod") {
        newObj[key] = t(value); // Translate the value using the i18n translation function
      } else if (key === "status") {
        newObj[key] = statusTranslations[value];
      } else {
        newObj[key] = value;
      }
    }
    return newObj;
  };

  const reportData = data?.map((item) => {
    if (item.paymentMethod || item.status) {
      return addLangToValues(item);
    }
    return item; // Return unmodified item if paymentMethod and status are not present
  });

  // const createReportMutation = ApiHook.CallCreateReport();
  // const downloadReport = (reportType, fileType) => {
  //   const data = {
  //     reportType:reportType,
  //     fileType: fileType
  //   };
  //   createReportMutation.mutateAsync(data, {
  //     onSuccess: (res) => {
  //       if (res.data.status) {
  //         toast.success(t(res.data.data));
  //       }
  //     }
  //   })
  // }

  return (
    <div className="filter_Section">
      <button className="print-button" style={{ backgroundColor: 'white' }} onClick={handleBack}>
        <i className="fa fa-angle-left" style={{ fontSize: "1.3em" }}></i>
      </button>
      <div className="row justify-content-end">
        <div className="col-md-4 text-end mob_filter_right">
          <LabelledButton
            className="fa fa-file-excel"
            text=" Excel"
            click={() => exportToExcel(reportData, reportHeaders, type, currencySymbol)}
            // click={() => downloadReport("repurchase", "xlsx")}
          />
          <LabelledButton
            className="fa fa-file-text"
            text=" CSV"
            click={() => exportToCSV(reportData, reportHeaders, type, currencySymbol)}
            // click={() => downloadReport("repurchase", "csv")}
          />
          <LabelledButton
            className="fa fa-print"
            text=" Print"
            click={handlePrint}
          />
        </div>
        <div style={{ display: "none", padding: "10px" }}>
          <PrintReport
            ref={tableRef}
            data={data}
            headers={headers}
            type={"repurchase-report"}
          />
        </div>

      </div>
    </div>
  );
};

export default RepurchaseTableFilter;
