import React, { useState } from "react";
import 'react-loading-skeleton/dist/skeleton.css'
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import GenealogyTreeContent from "../../components/Tree/genealogy-tree/GenealogyTreeContent";
import { ApiHook } from "../../hooks/apiHook";

const GenealogyTree = () => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [doubleUser, setDoubleUser] = useState("");
  const [showSearchLoader, setShowSearchLoader] = useState(false);
  // api
  const genealogyTree = ApiHook.CallGenealogyTreeList("", "", searchUsername);
  return (
    <>
      <TreeViewNavbar
        menu={"genealogyTree"}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
        setSelectedUserId={setSelectedUserId}
        setShowSearchLoader={setShowSearchLoader}
      />
      <GenealogyTreeContent
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        doubleUser={doubleUser}
        setDoubleUser={setDoubleUser}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
        isLoading={genealogyTree.isRefetching || genealogyTree.status === "loading"}
      />
    </>
  );
};

export default GenealogyTree;