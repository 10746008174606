import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import moment from "moment";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

const MailViewContent = ({
  selectedSingleMail,
  setSelectedSingleMail,
  mailContent,
  mailData,
  mailCheck,
  setMailCheck,
  activeTab,
  inboxes,
  selectedContacts,
  showMailPlaceholder,
  setShowMailPlaceholder,
  setApiTab,
  setPage,
  replyBtn,
  setReplyBtn
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const replyBoxRef = useRef(null);
  const [replyMessage, setReplyMessage] = useState("");
  const [expandedItems, setExpandedItems] = useState([]);
  const [isButtonActive, setIsButtonActive] = useState(true);

  const companyProfile = useSelector(
    (state) => state?.dashboard?.appLayout?.companyProfile
  );
  //----------------------------------------- API -------------------------------------------
  const singleMailDetails = ApiHook.CallSingleMailDetails(
    selectedSingleMail.mailId,
    mailCheck,
    setMailCheck,
    activeTab
  );
  const replyMutation = ApiHook.CallReplyMail();
  const deleteMailMutation = ApiHook.CallDeleteMail();

  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };
  //----------------------------------------- Functions -------------------------------------------
  const toggleAccordionItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const sendReply = () => {
    if (!replyMessage || replyMessage.trim().length === 0) {
      toast.error(t("Mail_Body_Is_Empty"));
      return
    }
    if(!isButtonActive) return;
    setIsButtonActive(false);
    const lastIndex = singleMailDetails?.data?.data?.mail?.length - 1;
    let subject = singleMailDetails?.data?.data?.mail?.[lastIndex].subject;

    // Conditionally prepend "Re:" if it doesn't already start with it
    if (!subject.startsWith("Re")) {
      subject = "Re: " + subject;
    }

    const data = {
      parentMailId: singleMailDetails?.data?.data?.mail?.[lastIndex].id,
      subject: subject,
      message: replyMessage
    };
    replyMutation.mutate(data, {
      onSuccess: (res) => {
        setIsButtonActive(true);
        if (res.status) {
          toast.success(t(res.data));
          setReplyBtn(false);
          setMailCheck(true);
          setReplyMessage("");
          queryClient.invalidateQueries({ queryKey: ["inboxes"] });
        } else if (res.code) {
          toast.error(t(res.description));
        } else {
          toast.error(res.data?.description);
        }
      },
    });
  };

  const deleteMail = (id, field, e) => {
    e.stopPropagation();
    const data = {
      mailId: activeTab === "replicaInbox" ? selectedContacts : [{id:id, thread:selectedSingleMail.thread}],
      type: activeTab === "replicaInbox" ? "contacts" : "inbox",
    };

    deleteMailMutation.mutateAsync(data, {
      onSuccess: (res) => {
        console.log(data,"payload");
        if (res?.status) {
          toast.success(t(res?.data));
          setSelectedSingleMail(prevState => ({
            ...prevState,
            mailId: "",
            thread: ""
          }));
          setMailCheck(false);
          setShowMailPlaceholder(true);
          setApiTab(activeTab);
          setPage(1);
          queryClient.invalidateQueries({ queryKey: [activeTab] });
        }
      },
    });
  };

  const isDeletable = () => {
    if (activeTab !== "replicaInbox") {
      return (
        !singleMailDetails?.data?.data?.mail ||
        singleMailDetails?.data?.data?.mail?.length === 0 ||
        showMailPlaceholder ||
        activeTab === "adminInbox"
      );
    } else {
      return true;
    }
  };

  const isReply = () => {
    return (
      !singleMailDetails?.data?.data?.mail ||
      singleMailDetails?.data?.data?.mail?.length === 0 ||
      showMailPlaceholder ||
      activeTab === "adminInbox" ||
      activeTab === "replicaInbox" ||
      singleMailDetails?.data?.data.mailType === "team"
    );
  };

  useEffect(() => {
    const lastIndex = singleMailDetails?.data?.data?.mail?.length - 1;
    if (lastIndex >= 0) {
      setExpandedItems([...Array(lastIndex).keys()]); // Collapse all items except the last one
    }
  }, [singleMailDetails?.data?.data?.mail]);

  useEffect(() => {
    if (replyBtn && replyBoxRef.current) {
      replyBoxRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [replyBtn]);

  
  return (
    <>
      <div className="row" style={{minHeight: "72px"}}>
        <div className="col-md-12 mb-4 mt-4">
          <div className="btn-toolbar">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  setReplyBtn(true);

                  // Scroll to the reply box
                  if (replyBoxRef.current) {
                    replyBoxRef.current.scrollIntoView({
                      behavior: "auto",
                    });
                  }
                }}
                disabled={isReply()}
                style={{ display: isReply() ? "none" : "block" }}
              >
                <i className="fa-solid fa-reply"></i> {t("reply")}
              </button>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={(e) => deleteMail(selectedSingleMail.mailId, "thread", e)}
                disabled={isDeletable()}
                style={{ display: isDeletable() ? "none" : "block" }}
              >
                <i className="fa-regular fa-trash-can"></i> {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showMailPlaceholder ? (
        <div className="no-mail-content">
          <img src="/images/no-mail.png" alt="" />
        </div>
      ) : (
        <div className="message-body">
          <div className="accordion" id="accordionExample">
            {activeTab !== "replicaInbox" ? (
              singleMailDetails?.data?.data?.mail?.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      aria-expanded={expandedItems.includes(index)}
                      onClick={() => toggleAccordionItem(index)}
                    >
                      <div className="sender-details">
                        <img
                          className="img-sm rounded-circle mr-3"
                          src={
                            item?.fromUserImage === null ||
                            item?.fromUserImage === undefined
                              ? "/images/user-profile.png"
                              : item.fromUserImage
                          }
                          alt=""
                        />
                        <div className="details">
                          <p className="msg-subject">
                            {t("subject")} : {item.subject}
                          </p>
                          <p className="sender-email">
                            {activeTab === 'sent' ? <>
                              {/* {t("to")}{" "}
                              {mailContent.filter(val => val.id === mailId).map(val => val.toUsername)[0]?.map((users, index) => (
                                <div key={index} className="sendMail-users">
                                  {users}
                                </div>
                              ))} */}
                               {t("to")}{" "}
                                {item.toUsername
                                  ? item.toUsername
                                  : item?.name ? item?.name : companyProfile?.name}{" "}
                            </>
                              :
                              <>
                                {t("from")}:{" "}
                                {item.fromUser + " " + '(' + " " + item.fromUsername + " " +')'}
                                {/* {item.fromUsername
                                  ? item.fromUsername
                                  : item?.name ? item?.name : companyProfile?.name}{" "} */}

                              </>}
                            <br />
                            <span>
                              {t("date")}:{" "}
                              {moment(item.createdAt).format(
                                "ddd, MMM D, YYYY [at] h:mm A"
                              )}
                            </span>
                            <i className="mdi mdi-account-multiple-plus"></i>
                          </p>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse ${
                      expandedItems.includes(index) ? "collapse" : ""
                    } `}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="message-content" style={{ height: "auto", overflow: "hidden" }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.message),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="accordion-item">
                {mailContent
                  .filter((inboxItem) => inboxItem.id === selectedContacts.id)
                  .map((inboxItem, index) => (
                    <div key={index}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          // aria-expanded={expandedItems.includes(index)}
                          // onClick={() => toggleAccordionItem(index)}
                        >
                          <div className="sender-details">
                            <img
                              className="img-sm rounded-circle mr-3"
                              src="/images/user-profile.png"
                              alt=""
                            />
                            <div className="details">
                              <p className="msg-subject">
                                {t("subject")} : {inboxItem.subject}
                              </p>
                              <p className="sender-email">
                                {t("from")}:{" "}
                                {inboxItem.fromUsername
                                  ? inboxItem.fromUsername
                                  : companyProfile?.name}{" "}
                                (
                                <a className="maildtl" href="#">
                                  {inboxItem.fromUserMail
                                    ? inboxItem.fromUserMail
                                    : companyProfile?.address}
                                </a>
                                ) <br />
                                <span>
                                  {t("date")}:{" "}
                                  {moment(inboxItem.createdAt).format(
                                    "ddd, MMM D, YYYY [at] h:mm A"
                                  )}
                                </span>
                                <i className="mdi mdi-account-multiple-plus"></i>
                              </p>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        // id={`collapse${index}`}
                        // className={`accordion-collapse ${
                        //   expandedItems.includes(index) ? "collapse" : ""
                        // } `}
                        // aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="message-content" style={{ height: "auto", overflow: "hidden" }}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(inboxItem.message),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {replyBtn && (
            <>
              <div className="reply_message mt-4" ref={replyBoxRef}>
                <ReactQuill
                  value={replyMessage}
                  onChange={setReplyMessage}
                  modules={modules}
                  maxLength={3000}
                  style={{ height: "200px" }}
                />
                <button className="send_btn mt-5" onClick={() => sendReply()}>
                  {t("send")}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MailViewContent;