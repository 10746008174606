import DownloadMaterialsComponent from "../../components/Tools/DownloadMaterialsComponent";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";

const DownloadMaterials = () => {
  const { t } = useTranslation();
  const DownloadMaterials = ApiHook.CallGetDownloadMaterials();
  return (
    <>
      <div className="page_head_top">{t("download-materials")}</div>
      <DownloadMaterialsComponent materials={DownloadMaterials?.data} />
    </>
  );
};
export default DownloadMaterials;
