import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  mailList: [],
  unReadCount:null
};

const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    setMails: (state, action) => {
      if (action.payload) {
        state.mailList = action?.payload;
      }
    },
    addMail: (state, action) => {
      if (action?.payload) {
        state.mailList.push(...action?.payload);
      }
    },
    setUnreadCount: (state, action) => {
      state.unReadCount = action?.payload;
    }
  },
});

export const { setMails, addMail, setUnreadCount } = mailSlice.actions;

export default mailSlice.reducer;
