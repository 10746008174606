// const BASE_URL = "http://162.19.146.134:1638/api/";
// const BASE_URL = "http://192.168.12.88:5004/api/";
const BASE_URL = "https://admin.messianicfamilyclub.com/api/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = '14959'
// const BASE_URL = "http://192.168.9.47:5002/";
// const BASE_URL = "http://192.168.9.47:5002/";


const PAYPAL_CLIENT_ID = "AeXp8oSTWcRihPjesILTOuXOjc42BXQ4CMNFsIUmkZza-tsG70g62j5c0uH3gN3n0ypUzYBpvReI2B48"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
